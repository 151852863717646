@import '~office-ui-fabric-react/dist/sass/_References.scss';
@import '../App.scss';

$bgColor: $ms-color-neutralLighter;

.loader {
    // padding-right: 10vw;
    width: 100%;
    :global{ 
            .ms-ProgressIndicator-itemProgress {
            width:100%;
        }
    }
}

.ms-Pivot-text{
    font-size: 11px;
}

.pivot {
    width: 100%;
    // padding-left: 2px!important;
    // padding-right: 2px!important;
    :global{ 
            .ms-Pivot {
            background-color: $bgColor;
            padding: 2px!important;
                .is-selected {
                    font-weight: normal;       
                }
                .is-selected::before {
                    border-bottom: "2px solid";
                    border-color: $smo-themePrimary;
                }
            }
            // [id*="-Tab0"] {
            //     padding-left: 1px!important;
            //     padding-right: 0px!important;
            //     margin-right: 0px!important;
            // }
    }
    div[role="tabpanel"] {
        padding-top: 8px ;
    }
    @media (max-width : 410px) {
        :global{ 
            .ms-Pivot {
            background-color: $bgColor;
            padding-left: 2px!important;
            .ms-Pivot-link {
                font-size: 11px;
            }
        //    [id*="-Tab0"] {
        //         padding-left: 0px!important;
        //         padding-right: 0px!important;
        //         margin-right: 0px!important;
        //     }
                .is-selected {
                    font-weight: normal;
                }
                .is-selected::before {
                    border-bottom: "2px solid";
                    border-color: $smo-themePrimary;
                }
            }
        }
    }
    @media (max-width : 286px) {
        font-size: 9px;
    }

}


#pivotHeader > div > div[class^='ms-FocusZone'] {
    padding: 2px!important;
}