@import '~office-ui-fabric-react/dist/sass/_References.scss';

$smo-themePrimary: #008caa;
$smo-themeSecondary: #005f7d;
$smo-green: #78af05;
$smo-green-20: rgba(121,175,5,0.2);
$smo-red: #a0002d;
$smo-red50: rgba(160,0,45,0.5);
$smo-blue: #007E9A;
$smo-text-small: 10px;
$smo-text-medium: 12px;
$smo-text-medium-high: 14px;
$smo-text-high: 18px;
$smo-text-higher: 20px;
$bgColor: $ms-color-neutralLighter;


a, u {
    text-decoration: none !important;
}

h1, h2, h3 {
    margin: 0;
}

h2 {
    color: $smo-themeSecondary;
}

.textBold {
    font-weight: bold;
}

.textSizeHigher {
    font-size: $smo-text-higher;
}

.bg-color-neutralLighter {
    background-color: $bgColor;
}
.color-themePrimary {
    color: $smo-themePrimary;
}
.bg-color-themePrimary{
    background-color: $smo-themePrimary;
}
.color-themeSecondary {
    color:$smo-themeSecondary;
}
.bg-color-themeSecondary{
    background-color: $smo-themeSecondary;
}
.color-green {
    color: $smo-green;
}
.bg-color-green{
    background-color: $smo-green;
}
.bg-color-green-20{
    background-color: $smo-green-20;
}
.color-red {
    color: $smo-red;
}
.bg-color-red{
    background-color: $smo-red;
}
.color-white {
    color: #ffffff;
}
.bg-color-white{
    background-color: #ffffff;
}
.color-blue {
    color: $smo-blue;
}

.dropdown {
    display: inline-block;
    :global div.ms-Dropdown-container {
        display: inline-flex;
        label {
            margin-right: 12px;
        }
    }
}

.noPaddingRight {
    padding-right: 0px!important;
}
.marginTopBottom {
    margin-top: 11px;
    margin-bottom: 12px;
    // padding: 2px;
}
.marginTopBottom2{
    margin-top: 10px;
    margin-bottom: 10px;
}

.marginTopBottom6{
    margin-top: 6px;
    margin-bottom: 6px;
}

.marginTopBottom8{
    margin-top: 8px;
    margin-bottom: 8px;
}

.marginTop10 {
    margin-top: 10px;
}

.marginTop10Important {
    margin-top: 10px!important;
}

.paddingTop10 {
    padding-top: 10px;
}

.marginLeft8 {
    margin-left: 8px!important;
}

.marginLeftRight0 {
    margin-left: 0px!important;
    margin-right: 0px!important;
}

.maringLeftRight8 {
    margin-left: 8px!important;
    margin-right: 8px!important;
}

.paddingLeftRight {
    padding-left: 6px;
    padding-right: 6px;
}

.paddingLeftRight8 {
    padding-left: 8px;
    padding-right: 8px;
}

.paddingLeft9{
    padding-left: 9px;
}
.paddingRight8 {
    padding-right: 8px;
}
.paddingRight5 {
    padding-right: 5px;
}
.paddingRight10{
    padding-right: 10px;
}

.paddingBottom5 {
    padding-bottom: 5px;
}

.paddingRight12 {
    padding-right: 12px;
}

.paddingTopBottom5 {
    padding-top: 5px;
    padding-bottom: 5px;
}
.paddingBottom10 {
    padding-bottom: 10px;
}

.filter-toggle {
    margin: 0px;
    padding-left: 0px!important;
}
.paddingTwoPix {
    padding: 2px!important;
}
.room-tile {
    @include ms-Grid-col;
    @include ms-lg4;
    @include ms-md6;
    @include ms-sm12;
    // border: 1px solid #818181;
    //margin: 3px;
    padding: 0 8px 0 8px;
    min-height: 181px;
}

.room-tile-l {
    @include ms-Grid-col;
    @include ms-lg12;
    @include ms-md12;
    @include ms-sm12;
    // border: 1px solid #818181;
    //margin: 3px;
    padding: 0 8px 0 8px;
    min-height: 181px;
}

.room-tile-header {
    background-color: $smo-themeSecondary;
    color: white;
    font-weight: bold;
    padding: 5px;
}

.room-tile-body {
    margin: 0px;
    //background-color: #ffffff;
    color: black;
}

@mixin room-tile-value {
    height: 50px;
    float: left;
    font-size: 16px;
    background: none;
    box-sizing: border-box;
    padding: 3px;
}

.room-tile-value-s {
    @include room-tile-value;
    width: 50%;
}

.room-tile-value-l {
    @include room-tile-value;
    width: 100%;
    font-size: 14px;
}

.room-tile-value-l > div, 
.room-tile-value-s > div {
    //background-color: #eeeeee;
    width: 100%; 
    height: 100%;
    box-sizing: border-box;
    padding: 3px;
}

.errorBar {
    background-color: $smo-red;
    color: white;
    height: 10px; 
    line-height:38px;
    text-align: center;
    transition: all .25s ease-in-out;
    overflow: hidden;
    // @include ms-slideDownIn20;
    :global {
        i.ms-Button-icon {
            color:white;
        }
    }
}
.errorBar:hover {
    height: 38px;
}

.panel-size {
    width: 40%;
}

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

.overviewIcon {
    font-size: 27px;
    float: left;
    padding-right: 14px;
    padding-left: 2px;
}

.overviewIconTextContainer {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
}

div.overview-component-overall {
    padding: 2px!important;
    margin-bottom: 5px;
}

.noPaddingMargin {
    padding-left: 0px!important;
    margin-left: 0px!important;
    padding-right: 0px!important;
    margin-right: 0px!important;
}

.noPaddingLeft {
    padding-left: 0px!important;
}

.noMarginTop {
    margin-top: 0px!important;
}

.breadcrumbIcon {
    margin-top: 14px;
}

.paddingLeft {
    padding-left: 5px;
}
.paddingTop {
    padding-top: 5px;
}

.paddingTop3 {
    padding-top: 3px;
}
.paddingRight16 {
    padding-right: 16px!important;
}

.breadcrumbCurrentItemText {
    font-weight: bold;
    font-size: $smo-text-high;
    padding-bottom: 3px;
}
    
@keyframes slide-in {
    100% { transform: translateX(0%); opacity: 1;}
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); opacity: 1; }
}
    
@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); opacity: 0.1; }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); opacity: 0.1; }
}

@media screen and (max-width: 540px) {
    span.overview-component-text {
        display: none;
    }
}

@media screen and (max-width: 410px) {
    h2 {
        font-size: 14px;
    }
    h3 {
        font-size: 11px;
    }
    div.breadcrumb {
        background-color: #ffffff;
    }
    .breadcrumb-actionbutton {
        font-size: 11px;
        margin: 0px;
        padding: 0px;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // width: 65px;
        // overflow: hidden;
    }
    .smallFontSize {
        font-size: 11px!important;
    }
    // div.breadcrumb-header {
    //     padding: 4px;
    // }
    // div.breadcrumb-grid-row {
    //     margin-bottom: 5px!important;
    // }

    div.filter-medium-devices {
        padding-left: 2px!important;
        padding-right: 2px!important;
    }
    i.location-icon {
        font-size: 16px!important;
        padding-right: 5px; 
    }
    i.list-component-icon {
        font-size: 14px;
        margin-top: 3px;
    }
    div.overview-component {
        font-size: 11px;
        margin-bottom: 0px;
    }
    .overview-component-header {
        font-size: 18px;
    }
    .panel-headline {
        font-size: 14px;
    }
    .panel-size {
        width: 50%;
    }
    div.overview-component-overall {
        display: none;
    }
    img.overview-component-img {
        width: 25px;
        height: 25px;
    }
    div.ms-grid-list {
        margin-bottom: 0px;
        margin-left: 8px;
        margin-right: 8px;
        padding-top: 12px;
    }
    span.overview-component-text {
        display: none;
    }
    span.list-component-text {
        font-size: 11px;
        margin-bottom: 0px;
    }
    .pivot-item {
        padding: 0px;
    }
    .padding-top {
        padding-top: 12px;
    }
    .filter-toggle {
        margin: 0px;
        padding-left: 0px!important;
    }
    .no-padding-left {
        padding-left: 0px!important;
        margin-right: 0px!important;
    }

    .overviewIconTextContainer {
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
        padding-top: 5px;
    }
    .breadcrumbIcon {
        margin-top: 16px;
        font-size: 10px;
    }
}

@media screen and (min-width: 411px) {
    i.location-icon {
        font-Size: 18px;
        padding-right: 5px; 
    }
    i.list-component-icon {
        font-size: 18px;
    }
    div.breadcrumb-header {
        padding: 0px;
    }
    .overview-component-header {
        font-size: 28px;
    }
    // div.breadcrumb-grid-row {
    //     margin-bottom: 10px!important;
    // }
    div.ms-grid-list {
        margin-bottom: 10px;
        margin-left: 8px;
        margin-right: 8px;
        padding-top: 12px;

    }
    .padding-top {
        padding-top: 12px;
    }
    .filter-toggle {
        margin: 0 30px 20px 0;
    }
    .filter-checkbox-padding {
        padding-left: 0px!important;
    }
    div.filter-medium-devices {
        display: none;
    }

    .width92{
        width: 92%;
    }
}

@media screen and (min-width:411px) and (max-height:450px){
    // div.breadcrumb-grid-row {
    //     margin-bottom: 0px!important;
    // }
    // div.overview-component-overall {
    //     display: none;
    // }
}

@media screen and (max-width: 640px) {
    .filter-toggle {
        //margin: 0px;
        padding-left: 0px!important;
    }
    .no-padding-left {
        padding-left: 0px!important;
        margin-right: 26px;
    }
    .padding-top {
        padding-top: 12px;
    }
    .panel-headline {
        font-size: 14px;
    }
}

// Also welche Varianten wollen wir denn?
// Wenn der Screen in Breite oder HÃ¶he 640px nicht Ã¼bersteigt: 14px
// bis 411px width: 50%, bis 850 40%, bis 1500 30%, danach 20%

@media screen and (max-width: 640px), screen and  (max-height: 640px) {
    .panel-headline {
        font-size: 14px;
    }
}

@media screen and (max-width: 411px), screen and  (max-height: 411px) {
    .panel-size {
        width: 50%;
    }
}

@media screen and (min-width: 850px) and (min-height: 500px) {
    .panel-size {
        width: 30%;
    }
}

@media screen and (max-width: 480px) {
    .hiddenSmall {
        display: none;
    }
    .paddingLeft16{
        padding-left: 16px!important;
    }
}

@media screen and (min-width: 1500px) and (min-height: 600px) {
    .panel-size {
        width: 30%;
    }
}

.floorTileIconText {
    margin-bottom: 5px;
    margin-right: 10px;
}
.displayInlineFlex {
    display: inline-flex;
}

.displayFlex {
    display: flex;
}

.floorTileIconContainer {
    position: absolute;
    bottom: 0px;
}

.textEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.numavailable {
    color: green;
    font-weight: bold;
}

/** -------------------------------------
 * ------------ ROOM DETAILS ------------
*/

// .room-detail-tile {
//     @include ms-Grid-col;
//     @include ms-md5;
//     @include ms-lg5;
//     @include ms-sm5;
//     border: 1px solid #818181;
//     margin: 3px;
//     padding: 0px;
//     min-height: 181px;
//     color: white;
// }

// .room-detail-tile h1,.room-detail-tile h2, .room-detail-tile h3 {
//     color: white;
// }

// .room-detail-tile-header {
//     background-color: $headerColor;
//     color: white;
//     font-weight: bold;
//     padding: 5px;
// }

// .room-detail-tile-body {
//     margin: 0px;
//     background-color: #ffffff;
//     color: black;
// }