:root {
	--occupied-color : var(--CSRed);// #a0002d;
	--available-color : var(--CSGreen);
	--cs-blue: var(--CSBlue);
	--cs-orange: var(--OccupiedIndicationBrush);
}

//@fontColor : #7d7d7d;
@rotation-degrees-x: 65deg; // 65deg;
@rotation-degress-z: 200deg; // 200deg;
@map-rotation-x: rotateX(@rotation-degrees-x);
@map-rotation-z: rotateZ(@rotation-degress-z);
@undo-map-rotation-x: rotateX(-@rotation-degrees-x);
@undo-map-rotation-z: rotateZ(-@rotation-degress-z);
@map-rotation: @map-rotation-x @map-rotation-z;
@undo-map-rotation: @undo-map-rotation-z @undo-map-rotation-x;
@pin-height:50px;
@pin-width:40px;


.rotated {
	transform: @map-rotation;
}

.unRotated {
	transform: @undo-map-rotation;
}

.floorPlanPage {
	width: 100%;
	height: 100%;

	#floorMap {
		height:100%;
	}
}

#floormapWrapper {
	height: 80vh;
}

#floorMap {
	height: 90%;
	width: 100%;
	display:flex;
	perspective: 2500px;
	transform-style: preserve-3d;
	transform-origin: 50% 50%;
	
	#rotationWrapper {
		transform-origin: 50% 50%;
		transform-style: preserve-3d;

		#currentFloor {
			width: 100%;
			height: 100%;
			position:absolute;
			transform-style: preserve-3d;

			#overlays {
				pointer-events: none;
				z-index: 1;
				display:block;
				transform-style: preserve-3d;
				position: absolute;
				top:0px;
				left: 0px;
				height:100%;
				width:100%;

				svg.pin{
					&.unRotated {
						transform: translateX(-35px) translateY(-70px) @undo-map-rotation;
					}

					transform-style: preserve-3d;
					width:70px;
					transform-origin: 50% 100%;
					transform: translateX(-35px) translateY(-70px);
					transform-box: fill-box;
					position: absolute;

					.pinCircle {
						fill: white;
					}

					.peopleLabel .peopleCount {
						fill: black;
					}
				}

				.iconWrapper {
					transform-origin: 50% 100%;
					transform-style: preserve-3d;
					position: absolute;
					z-index: 2;
					transform-box: fill-box;
				}

				svg.icon {
					position:absolute;
					width:100%;
					height:100%;
					z-index: 2;
					overflow: visible;
					transform-box: fill-box;
				}
			}
	
			div {
				position:absolute
			}
	
			>svg {
				position:absolute;
				top:0px;
				left: 0px;
				height: 100%;
				width: 100%;
				transform-style: preserve-3d;
	
				circle {
					pointer-events: none;
					fill:transparent;
					stroke:#595959;
					stroke-width:0.11%;
				}

				text,tspan {
					pointer-events:none;
					font-family: 'Segoe UI';
					font-style: normal;
				}

				> svg {
					transform-style: preserve-3d;
					height:100%;
					width: 100%;
				}
	
				> g {
					transform-style: preserve-3d;
					pointer-events: none;
	
					circle {
						transform-style: preserve-3d;
						transform-box: fill-box;
						transform-origin: 50% 100%;
					}
				}
			}
		}
	}
}

.room {
	fill: #bdbdbd !important;
}

.occupied {
    fill: var(--cs-blue) !important;
	fill-rule: evenodd;
	fill-opacity: 1 !important;
}

.floorPlanPage .occupied {
    fill: var(--cs-blue) !important;
    fill-rule: evenodd;
}

.free {
    fill: var(--available-color) !important;
    fill-rule: evenodd;
	stroke: var(--available-color);
	fill-opacity: 1 !important;

}

.pin {
	outline-color: black;
	stroke: black;
	stroke-width: 7.5px;

	path {
		background-color:white;
	}

	text {
		transform: translate(32px, 25px);

		.peopleIcon {
			font-family: 'Segoe MDL2 Assets';
			font-size: 170px;
		}

		&.singleDigit {
			transform: translate(5px, 20px);
			font-size: 170px;
			.peopleIcon {
				font-size: 130px;
			}
			
		}
		&.doubleDigit {
			transform: translate(-5px, 0px);
			font-size: 130px;
			.peopleIcon {
				font-size: 100px;
			}
		}

		.pending {
			fill: var(--cs-orange) !important;
			
		}
	
		.occupied {
			fill: var(--occupied-color) !important;
		}
	
		.doubleDigit {
			font-size: 120px;
	
			&.peopleIcon {
				font-size: 90px;
			}
		}
	}
}

.pending {
    fill: var(--cs-orange) !important;
	fill-rule: evenodd;
	fill-opacity: 1 !important;
}

