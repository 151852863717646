@import '~office-ui-fabric-react/dist/sass/_References.scss';

.listItem {
    transition: all .25s ease-in-out;
    // transition: translate (random(50)* 0.01s) ease;
    // transform: translateX(100%);
    // -webkit-transform: translateX(100%);
    // opacity: 0.1;
    // right: -100vw;
    // position: relative;
    // opacity: 0;
    // -webkit-animation: slide 0.5s forwards;
    // -webkit-animation-delay: 0.5s;
    // animation: slide 0.5s forwards;
    // animation-delay: 0.5s
}

@-webkit-keyframes slide {
    100% { right: 0; opacity: 1; }
}

@keyframes slide {
    100% { right: 0; opacity: 1;}
}