.listItem {
  transition: all .25s ease-in-out;
}

@-webkit-keyframes slide {
  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    right: 0;
    opacity: 1;
  }
}

