@import '~office-ui-fabric-react/dist/sass/_References.scss';
@import '../App.scss';

$textColor: $ms-color-white;
$textHover: $ms-color-neutralQuaternaryAlt;
$bgColor: $ms-color-themePrimary;

.ratingStars {
    padding: 5px 0;
    :global{ 
        .ms-Rating-button {
            width: 40px;
            min-width: auto;
        }
        .ms-RatingStar-front {
            color: $smo-themePrimary;
        }
    }
}