@import '~office-ui-fabric-react/dist/sass/_References.scss';
@import '../App.scss';

$bgColor: $ms-color-neutralLighter;

.listGrid {
    overflow: hidden;
    position: relative;
}

.listGridTile {
    text-align: left;
    outline: none;
    position: relative;
    float: left;
    background: white;
    .selectors {
        .focus::after {
            position: absolute;
            left: 2px;
            right: 2px;
            top: 2px;
            bottom: 2px;
            box-sizing: border-box;
            border: 1px solid white;
        }
    }
}

.listGridSizer {
    padding-bottom: 100%;
    cursor: pointer;
}

.listGridPadder {
    position: absolute;
    padding: 5px;
    left: 2px;
    top: 2px;
    right: 2px;
    bottom: 2px;
    background: $bgColor;
}

.listGridLabel {
    background: #008caa;
    color: #FFFFFF;
    position: absolute;
    padding: 10px;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.listGridRowContainer {
    position: relative;
    top: 30px;
}

.listGridRow {
    position: relative;
    padding: 4px;
    margin: auto;
    width: 100%;
    left: 0;
    font-size: 12px;
    box-sizing: border-box;

	.shimmerEl {
		padding:6px;
	}
}

.listGridRowText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px;
    font-size: 12px;
}

.listGridIcon {
    font-size: 25px;
    float: left;
    padding-top: 7px;
    padding-right: 7px;
    padding-bottom: 3px;
}

.listGridRoomContainer {
    margin-top: 10px;
}

@media screen and (max-width: 410px) {
    .listGridIcon {
        font-size: 20px;
        float: left;
        padding-top: 7px;
        padding-right: 7px;
        padding-bottom: 3px;
    }

    .listGridRow {
        position: relative;
        padding: 3px;
        margin: auto;
        width: 100%;
        left: 0;
        font-size: 11px;
        box-sizing: border-box;
    }

    .listGridLabel {
        background: #008caa;
        color: #FFFFFF;
        position: absolute;
        // padding: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 8px;
        padding-right: 8px;
        top: 0;
        left: 0;
        width: 100%;
        font-size: 12px;
        font-weight: bold;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media screen and (max-width: 360px) {
    .listGridIcon {
        font-size: 20px;
        float: left;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-right: 7px;
        padding-left: 5px;
    }

    .listGridRow {
        position: relative;
        padding: 0px;
        margin: auto;
        width: 100%;
        left: 0;
        font-size: 11px;
        box-sizing: border-box;
    }

    .listGridRowText {
        padding-bottom: 3px;
    }

    .listGridSpanText {
        display: none;
    }
}

@media screen and (min-width: 534px) and (max-width: 630px) {
    .listGridRow {
        font-size: 11px;
    }

    .listGridRowText {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .listGridIcon {
        padding-top: 2px;
    }
}